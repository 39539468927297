import {Component} from '@angular/core';
import {MediaService} from '../services/public_api';
import {MediaStateInterface} from '../interfaces/media-state.interface';

@Component({
  selector: 'app-media-timer',
  templateUrl: './media-timer.component.html',
  styleUrls: ['./media-timer.component.scss']
})
export class MediaTimerComponent {
  currentTime = 0;
  mediaState: MediaStateInterface;

  constructor(private mediaService: MediaService) {
    this.mediaService.currentTime.subscribe((currentTime) => {
      this.currentTime = currentTime;
    });

    this.mediaService.media.subscribe((media) => {
      this.mediaState = media;
    });
  }
}
