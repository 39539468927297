import {NgModule} from '@angular/core';
import {CoreModule} from '../core/core.module';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {AutosizeModule} from 'ngx-autosize';
import {BookmarkCreateComponent} from './bookmark-create/bookmark-create.component';
import {BookmarkTimestampComponent} from './bookmark-timestamp/bookmark-timestamp.component';
import {BookmarkComponent} from './bookmark/bookmark.component';
import {BookmarksListComponent} from './bookmarks-list/bookmarks-list.component';
import {BookmarkViewComponent} from './bookmark-view/bookmark-view.component';
import {BookmarkEditComponent} from './bookmark-edit/bookmark-edit.component';
import {BookmarkTimestampEditComponent} from './bookmark-timestamp-edit/bookmark-timestamp-edit.component';
import {BookmarkPinsComponent} from './bookmark-pins/bookmark-pins.component';
import {BookmarkPinComponent} from './bookmark-pin/bookmark-pin.component';

@NgModule({
  declarations: [
    BookmarkCreateComponent,
    BookmarkTimestampComponent,
    BookmarkComponent,
    BookmarksListComponent,
    BookmarkViewComponent,
    BookmarkEditComponent,
    BookmarkTimestampEditComponent,
    BookmarkPinsComponent,
    BookmarkPinComponent,
  ],
  exports: [
    BookmarkCreateComponent,
    BookmarksListComponent,
    BookmarkPinsComponent,
    BookmarkPinComponent
  ],
  imports: [
    CoreModule,
    AutosizeModule,
    TimepickerModule.forRoot()
  ]
})
export class BookmarksModule {
}
