import { Component, Input } from '@angular/core';
import { ChapterInterface } from '../interfaces/public_api';
import { MediaService } from '../services/media.service';

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss']
})
export class ChapterComponent {
  @Input() chapter: ChapterInterface;

  constructor(private mediaService: MediaService) {
  }

  goToChapterTime(chapterTime: number): void {
    this.mediaService.seekTime(chapterTime);
  }
}
