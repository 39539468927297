<div class="btn-group only-lg" dropdown [dropup]="true" *ngIf="media">
  <button dropdownToggle class="dropdown-toggle btn btn-media-control settings-control" type="button"></button>
  <div *dropdownMenu class="dropdown-menu media-dropdown-menu-container">
    <ul class=" media-dropdown-menu settings-dropdown-menu" role="menu">
      <li *ngFor="let level of media?.levels" role="menuitem" class="dropdown-item"
          [class.active]="level.enabled && mode === mediaType.VIDEO"
          (click)="setQualityLevel(level.height)">
        {{ level.height }} <sup *ngIf="level.sup">{{ level.sup }}</sup>
      </li>
      <li role="menuitem" class="dropdown-item"
          *ngIf="mode === mediaType.AUDIO"
          (click)="mediaService.switchMedia()">
        <span i18n="@@videoVersion">Version vidéo</span>
      </li>
      <li role="menuitem" class="dropdown-item"
          [class.active]="mode === mediaType.AUDIO"
          (click)="mediaService.switchMedia()">
        <span i18n="@@audioVersion">Version audio</span>
      </li>
    </ul>
  </div>
</div>

<button type="button" class="btn btn-media-control settings-control only-sm" (click)="settingsModal.show()"></button>

<section class="modal modal-settings fade" bsModal #settingsModal="bs-modal" tabindex="-1" role="dialog"
         (onShow)="onShowModal()"
         (onHide)="onHideModal()"
         aria-labelledby="dialog-media-settings">
  <div class="modal-dialog modal-sm">
    <div class="modal-content modal-settings__content">
      <div class="modal-header modal-settings__header">
        <h1 id="dialog-media-settings" class="modal-title modal-settings__title" i18n="@@parameters">Paramètres</h1>
        <button type="button" class="close modal-settings__close" aria-label="Close" (click)="settingsModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-settings__body">

        <div class="modal-settings__radios-list">
          <h2 class="modal-settings__subtitle" i18n="@@quality">Qualité</h2>
          <label class="modal-settings__radio radio" *ngFor="let level of media?.levels">
            <input class="hidden" type="radio" name="level" role="button" tabindex="0"
                   [checked]="level.enabled === true"
                   [value]="level.height"
                   (change)="setQualityLevel(level.height)">
            <span class="radio__input"></span>
            {{ level.height }}&nbsp;<sup *ngIf="level.sup">{{ level.sup }}</sup>
          </label>
          <label class="modal-settings__radio radio" *ngIf="mode === mediaType.AUDIO">
            <input class="hidden" type="radio" name="level" role="button" tabindex="0"
                   [value]="mediaType.VIDEO"
                   (change)="mediaService.switchMedia()">
            <span class="radio__input"></span>
            <span i18n="@@videoModal">Version vidéo</span>
          </label>
          <label class="modal-settings__radio radio">
            <input class="hidden" type="radio" name="level" role="button" tabindex="0"
                   [value]="mediaType.AUDIO"
                   (change)="mediaService.switchMedia()">
            <span class="radio__input"></span>
            <span i18n="@@audioModal">Version audio&nbsp;&#9834;</span>
          </label>
        </div>

        <div class="modal-settings__radios-list">
          <h2 class="modal-settings__subtitle" i18n="@@readspeed">Vitesse de lecture</h2>
          <label class="modal-settings__radio radio" *ngFor="let playbackRate of media?.playbackRates">
            <input class="hidden" type="radio" name="playbackRate" role="button" tabindex="0"
                   [checked]="playbackRate === media.currentPlaybackRate"
                   [value]="playbackRate"
                   (change)="changePlaybackRate(playbackRate)">
            <span class="radio__input"></span>
            {{ playbackRate }}&nbsp;x
          </label>
        </div>
      </div>
    </div>
  </div>
</section>
