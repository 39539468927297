import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef, OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { PodcastService } from '../../core/services/podcast.service';
import { PodcastInterface } from '../../core/interfaces/podcast.interface';
import { pickBy, identity } from 'lodash';
import { Subscription } from 'rxjs';
import { LiveVideoComponent } from '../live-video/live-video.component';
import { FeedsInterface } from '../../core/interfaces/feeds.interface';
import { forIn } from 'lodash/fp';

@Component({
  selector: 'app-live-player',
  templateUrl: './live-player.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LivePlayerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('videoContainer', {read: ViewContainerRef}) videoContainer: ViewContainerRef;
  podcast: PodcastInterface;
  /**
   * List of feed where the key is the feed name and the value is the source of the feed
   */
  feeds: FeedsInterface;
  liveVideoComponentFactory = this.componentFactory.resolveComponentFactory(LiveVideoComponent);

  videoComponents: { id: string, component: ComponentRef<LiveVideoComponent> }[] = [];
  videoSubscriptions: { [id: string]: Subscription[] } = {};

  constructor(
    private componentFactory: ComponentFactoryResolver,
    private podcastService: PodcastService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.podcastService.getPodcast().subscribe(podcast => {
      this.podcast = podcast;
      this.feeds = pickBy(this.podcast.feeds, identity);
    }, (error) => {
      console.error(error);
    });
  }

  onVideoClicked(videoId: string) {
    this.switchShrunkCapture(videoId);
  }

  ngAfterViewInit(): void {
    let i = 0;
    this.videoContainer.clear();
    for (const key in this.feeds) {
      if (Object.prototype.hasOwnProperty.call(this.feeds, key)) {
        const shrunk = i++ > 0;
        this.createComponent(key, this.feeds[key], shrunk);
        this.changeDetector.detectChanges();
      }
    }
  }

  ngOnDestroy() {
    forIn((subs) => {
      subs.forEach(sub => sub.unsubscribe());
    }, this.videoSubscriptions);
  }

  private createComponent(feedId: string, feedUrl: string, shrunk = false) {
    const component = this.videoContainer.createComponent<LiveVideoComponent>(this.liveVideoComponentFactory);
    const instance: LiveVideoComponent = component.instance;
    instance.shrunk = shrunk;
    instance.feedUrl = feedUrl;
    instance.videoId = feedId;

    this.videoComponents.push({id: feedId, component});
    this.videoSubscriptions[feedId] = [
      instance.videoClicked.subscribe((videoId: string) => {
        this.onVideoClicked(videoId);
      }),
    ];

    this.changeDetector.detectChanges();
  }

  private getComponent(id: string): ComponentRef<LiveVideoComponent> {
    return this.videoComponents.find(
      (componentObj) => componentObj.id === id).component;
  }

  private switchShrunkCapture(clickedId: string) {
    const clickedVideoInstance = this.getComponent(clickedId).instance;
    if (this.videoComponents.length > 1 && clickedVideoInstance.shrunk) {
      this.videoComponents.forEach((componentObj) => {
        componentObj.component.instance.shrunk = !componentObj.component.instance.shrunk;
      });
    }
  }
}
