<button class="btn btn-media-control skip-control"
        [ngClass]="'skip-control--' + controlLabel"
        (click)="skipTime(skippedTime)">
    <span class="skip-control__text">

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 25">
        <text transform="translate(10.24 17.66)"
              style="isolation:isolate" font-size="9"
              fill="#fff"
              font-family="OpenSans-Bold,Open Sans"
              font-weight="700">
          {{skippedTime | absoluteNumber}}
        </text>
      </svg>

      <span class="sr-only"><span i18n="@@skip">Passer</span> {{controlLabel}} {{skippedTime | absoluteNumber}} <span i18n="@@seconds">secondes</span></span>
    </span>
</button>
