import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { SharingBtn } from "../../core/models";

@Component({
  selector: 'app-sharing-button',
  template: '',
})
export class SharingButtonComponent implements OnInit {
  private readonly element: HTMLElement;

  @Input() podcastId: string;

  constructor(private elementRef: ElementRef) {
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    const sharingBtn = new SharingBtn(this.element, 'sharing-btn-tmpl');
    sharingBtn.createButton();
  }
}
