import { Component, HostListener } from '@angular/core';
import { MediaService } from '../services/media.service';

@Component({
  selector: 'app-fullscreen-control',
  templateUrl: './fullscreen-control.component.html',
  styleUrls: ['./fullscreen-control.component.scss']
})
export class FullscreenControlComponent {
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'f') {
      this.toggleFullscreen();
    }
  }

  constructor(private mediaService: MediaService) {}
  toggleFullscreen() {
    this.mediaService.toggleFullscreen();
  }
}
