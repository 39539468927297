<div class="btn-group" dropdown dropup="true">
  <button dropdownToggle type="button" class="dropdown-toggle btn btn-media-control playback-rate-control">
    x{{media?.currentPlaybackRate}}
  </button>
  <div *dropdownMenu class="dropdown-menu media-dropdown-menu-container">
    <ul class="media-dropdown-menu" role="menu">
      <li role="menuitem" class="dropdown-item"
          *ngFor="let playbackRate of media?.playbackRates"
          [class.active]="playbackRate === media?.currentPlaybackRate"
          (click)="changePlaybackRate(playbackRate)">
        {{playbackRate}}
      </li>
    </ul>
  </div>
</div>
