<div class="chapter" (click)="goToChapterTime(chapter.startTime)">
  <div class="chapter__image-wrapper">
    <img class="chapter__image" src="{{chapter.image}}" alt="">
  </div>
  <div class="chapter__description">
    <div class="chapter__title">
      {{chapter.title}}
    </div>
    <time class="chapter__timer">
      {{chapter.startTime | secondsToHHMMSS}}
    </time>
  </div>
</div>
