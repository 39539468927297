import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MediaModule} from './media/media.module';

const routes: Routes = [
  {path: 'media', loadChildren: './media/media.module#MediaModule'},
  {path: '', redirectTo: 'media', pathMatch: 'full'}, // redirect to media
];

@NgModule({
  imports: [
    MediaModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
