import { Component, HostListener } from '@angular/core';
import { MediaService } from '../services/media.service';
import { MediaStateInterface } from '../interfaces/media-state.interface';

@Component({
  selector: 'app-playbackrate-control',
  templateUrl: './playbackrate-control.component.html',
  styleUrls: ['./playbackrate-control.component.scss']
})
export class PlaybackrateControlComponent {
  media: MediaStateInterface;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === '<' || event.key === '>') {
      const currentPlaybackRateIndex = this.media.playbackRates.indexOf(this.media.currentPlaybackRate);
      if (event.key === '<') {
        if (currentPlaybackRateIndex > 0) {
          this.changePlaybackRate(this.media.playbackRates[currentPlaybackRateIndex - 1]);
        }
      }
      if (event.key === '>') {
        if (currentPlaybackRateIndex < this.media.playbackRates.length - 1) {
          this.changePlaybackRate(this.media.playbackRates[currentPlaybackRateIndex + 1]);
        }
      }
    }
  }

  constructor(private mediaService: MediaService) {
    this.mediaService.media.subscribe((media) => {
      this.media = media;
    });
  }

  changePlaybackRate(newPlaybackRate: number) {
    this.mediaService.changePlaybackRate(newPlaybackRate);
  }
}
