export class DownloadBtn {
  podcastId: string;
  container: HTMLElement;
  button: HTMLElement;

  private htmlTemplate: string;

  constructor(container: string | HTMLElement, podcastID: string, template?: string | HTMLElement) {
    this.setContainer(container);
    this.podcastId = podcastID;
    this.setTemplate(template);
  }

  createButton() {
    const domParser = new DOMParser();
    const document = domParser.parseFromString(this.htmlTemplate, 'text/html');
    const button = document.querySelector('body > *');
    this.container.appendChild(button);
  }

  private setTemplate(template: string | HTMLElement) {
    const defaultTemplate = `<div id="download-dropdown-$podcastId" class="dropdown download-podcast">
    <button class="btn btn-secondary dropdown-toggle dropdown-toggle--with-arrow download-podcast__cta"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            title="Télécharger">

      <svg viewBox="0 0 16 16" class="download-podcast__icon" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
        <path fill-rule="evenodd"
              d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
      </svg>

      <span class="download-podcast__spinner">
        <span class=" spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
    </button>

    <div class="dropdown-menu">
        <button class="btn dropdown-item download-podcast__dropdown-item"
                type="button"
                data-hires="true"
                data-podcast-id="$podcastId">
          Télécharger la version HD
        </button>
        <button class="btn dropdown-item download-podcast__dropdown-item"
                type="button"
                data-hires="false"
                data-podcast-id="$podcastId">
          Télécharger la version SD
        </button>
    </div>
  </div>`;
    let tmpl: string;
    if (!template) {
      tmpl = defaultTemplate;
    } else if (typeof template === 'string') {
      const element = document.querySelector(`[data-template-id=${template}]`);
      if (element) {
        tmpl = element.outerHTML;
      } else {
        tmpl = defaultTemplate;
        console.warn(`Download button container could not be found via id ${template}. Fallback to default template.`);
      }
    } else if ((template as HTMLElement).outerHTML) {
      tmpl = (template as HTMLElement).outerHTML;
    } else {
      throw new Error('Invalid template provided');
    }

    this.htmlTemplate = tmpl.replace(/\$podcastId/g, this.podcastId);
  }

  private setContainer(container: string | HTMLElement) {
    const error = 'Container should be an element or an ID of an element';
    if (typeof container === 'string') {
      if (this.isIdSelector(container)) {
        this.container = document.querySelector(container);
      } else {
        // tslint:disable-next-line:no-console
        console.error(error);
      }
    } else if (container) {
      this.container = container as HTMLElement;
    } else {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  }

  private isIdSelector(selector: string) {
    return /^#/.test(selector);
  }
}
