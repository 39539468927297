import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable()
export class ConfigService {
  apiUrl: string;
  language: string;
  private professorStatus: boolean;
  private embeddedStatus: boolean;

  constructor(@Inject(LOCALE_ID) lang: string) {
    if (lang === 'en-US') {
      this.language = 'fr';
    } else {
      this.language = lang;
    }
  }

  setApiUrl(apiUrl) {
    if (!apiUrl) {
      console.error('The API url could not be defined');
      return false;
    }
    this.apiUrl = apiUrl;
    return true;
  }

  set professor(professor: boolean) {
    this.professorStatus = professor
  }

  get professor(): boolean {
    return this.professorStatus;
  }

  set embedded(embedded: boolean) {
    this.embeddedStatus = embedded;
  }

  get embedded(): boolean {
    return this.embeddedStatus;
  }
}
