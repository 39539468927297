import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AbsoluteNumberPipe, SecondsToHHMMSSPipe } from './pipes/public_api';
import { ContextMenuItemDirective } from './directives/public_api';
import { ConfigService, PodcastService } from './services/public_api';

@NgModule({
  declarations: [
    AbsoluteNumberPipe,
    SecondsToHHMMSSPipe,
    ContextMenuItemDirective,
  ],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    BsDropdownModule,
    PopoverModule,
    ModalModule,
    AbsoluteNumberPipe,
    SecondsToHHMMSSPipe,
    ContextMenuItemDirective,
  ],
  providers: [ConfigService, PodcastService]
})
export class CoreModule {
}
