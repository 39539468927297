import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaStateInterface } from '../interfaces/public_api';
import { PodcastService } from '../../core/services/podcast.service';
import { ControlBarComponent } from '../control-bar/control-bar.component';
import { MediaService } from '../services/media.service';
import videoJs from 'video.js';
import 'videojs-contrib-quality-levels';
import { MediaTypeEnum } from '../enums/media-type.enum';
import { timer } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ConfigService } from "../../core/services/config.service";

@Component({
  selector: 'app-media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MediaPlayerComponent implements AfterViewInit {
  @ViewChild('video', { static: true }) video: ElementRef;
  @ViewChild('mediaOverlay', { static: true }) mediaOverlay: ElementRef;
  @ViewChild(ControlBarComponent, { read: ElementRef }) controlBar: ElementRef;
  media: MediaStateInterface;
  mediaType = MediaTypeEnum;
  mode: MediaTypeEnum;
  /**
   *  Indicates that we add a view to the podcast. Switch to false after first sending
   */
  addView = !this.configService.embedded;
  addExternalView = this.configService.embedded;

  constructor(
    private mediaService: MediaService,
    private renderer: Renderer2,
    private podcastService: PodcastService,
    private configService: ConfigService) {

    this.mediaService.media.subscribe((media) => {
      this.media = media;
    });

    this.mediaService.modeChange.subscribe((mode) => {
      this.mode = mode;
    });
  }

  ngAfterViewInit() {
    this.podcastService.getPodcast().subscribe(podcast => {
      const options = {
        autoplay: false,
        controls: false,
        sources: [{
          src: podcast.media,
          type: 'application/x-mpegURL',
          withCredentials: false,
        }],
        ...(videoJs.browser.IS_SAFARI && {
          html5: {
            hls: {
              overrideNative: true,
              nativeAudioTracks: false,
              nativeVideoTracks: false,
            }
          }
        })
      };

      /**
       * Set the instance a new instance of videoJs using the fetched podcast m3u8
       */
      const videoJsInstance = videoJs(this.video.nativeElement, options, () => {
        this.mediaService.setMediaInstance(videoJsInstance).subscribe(() => {
          this.renderer.appendChild(this.media.instance.el(), this.mediaOverlay.nativeElement);
          this.renderer.appendChild(this.media.instance.el(), this.controlBar.nativeElement);

          if (podcast.startTime > 0 && podcast.startTime < podcast.length) {
            videoJsInstance.one('loadedmetadata', () => {
              this.mediaService.seekTime(podcast.startTime);
            });
          }

          const observable = timer(0, 5000)
            .pipe(
              mergeMap(() => this.podcastService.setNewPodcastTime(videoJsInstance, this.addView, this.addExternalView)),
              tap(() => {
                if (this.addView) {
                  this.addView = false;
                }
                if(this.addExternalView) {
                  this.addExternalView = false;
                }
              }),
              takeUntil(this.mediaService.paused),
            );

          videoJsInstance.on('play', () => {
            this.mediaService.played.next();
          });

          videoJsInstance.on('pause', () => {
            this.mediaService.paused.next();
          });

          this.mediaService.played.subscribe(() => {
            observable.subscribe();
          });
        });
      });
    });
  }
}
