import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MediaService } from '../services/media.service';

@Component({
  selector: 'app-skip-control',
  templateUrl: './skip-control.component.html',
  styleUrls: ['./skip-control.component.scss']
})
export class SkipControlComponent implements OnInit {
  @Input() skippedTime: number;
  controlLabel: string;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.skippedTime > 0) {
      if (event.key === 'ArrowRight') {
        this.mediaService.skipTime(this.skippedTime);
      }
    } else {
      if (event.key === 'ArrowLeft') {
        this.mediaService.skipTime(this.skippedTime);
      }
    }
  }

  constructor(private mediaService: MediaService) {}

  skipTime(skippedTime: number) {
    this.mediaService.skipTime(skippedTime);
  }

  ngOnInit(): void {
    this.controlLabel = this.skippedTime > 0 ? 'forward' : 'backward';
  }
}
