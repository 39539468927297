import {Component} from '@angular/core';
import { ChapterInterface } from '../interfaces/chapter.interface';
import { MediaService } from '../services/media.service';

@Component({
  selector: 'app-control-bar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.scss']
})
export class ControlBarComponent {
  chapters: ChapterInterface[];

  constructor(private mediaService: MediaService) {
    this.mediaService.chapters.subscribe((chapters) => {
      this.chapters = chapters;
    });
  }
}
