import { Component } from '@angular/core';
import { ChapterInterface } from '../interfaces/chapter.interface';
import { MediaService } from '../services/media.service';
import { MediaStateInterface } from '../interfaces/media-state.interface';

@Component({
  selector: 'app-chapters-control',
  templateUrl: './chapters-control.component.html',
  styleUrls: ['./chapters-control.component.scss']
})
export class ChaptersControlComponent {
  media: MediaStateInterface;
  chapters: ChapterInterface[];
  inactivityTimeout: number;

  constructor(private mediaService: MediaService) {
    this.mediaService.media.subscribe((media) => {
      this.media = media;
    });

    this.mediaService.chapters.subscribe((chapters) => {
      this.chapters = chapters;
    });
  }

  onShowChapters(): void {
    this.inactivityTimeout = this.media.instance.options().inactivityTimeout;
    this.media.instance.options().inactivityTimeout = 0;
  }

  onHideChapters(): void {
    this.media.instance.options().inactivityTimeout = this.inactivityTimeout;
  }
}
