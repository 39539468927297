import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaService } from '../services/public_api';
import { MediaStateInterface } from '../interfaces/media-state.interface';

@Component({
  selector: 'app-progress-control',
  templateUrl: './progress-control.component.html',
  styleUrls: ['./progress-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressControlComponent implements AfterViewInit {
  @ViewChild('progressControl') progressControl: ElementRef;
  media: MediaStateInterface;
  progressBar: HTMLElement;

  constructor(private renderer: Renderer2, private mediaService: MediaService) {
  }

  ngAfterViewInit() {
    this.mediaService.media.subscribe((media) => {
      this.media = media;
      this.progressBar = this.media.instance.controlBar.progressControl.el();
      this.renderer.appendChild(this.progressControl.nativeElement, this.progressBar);
    });
  }
}
