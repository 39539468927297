import { Component } from '@angular/core';
import { PodcastService } from '../../core/services/podcast.service';
import { PodcastInterface } from '../../core/interfaces/podcast.interface';
import { ChapterInterface } from '../interfaces/public_api';
import { MediaService } from '../services/media.service';
import { PodcastStateEnum } from '../../core/enums/public_api';
import { ConfigService } from "../../core/services/config.service";

@Component({
  selector: 'app-media-page',
  templateUrl: './media-page.component.html',
  styleUrls: ['./media-page.component.scss'],
})
export class MediaPageComponent {
  loading = true;
  failedToGetPodcast = false;
  podcast: PodcastInterface | null;
  podcastState = PodcastStateEnum;
  chapters: ChapterInterface[];

  constructor(
    private podcastService: PodcastService,
    private mediaService: MediaService,
    public configService: ConfigService,
  ) {
    this.podcastService.getPodcast().subscribe(podcast => {
      this.podcast = podcast;
      this.loading = false;
    }, (error) => {
      console.error(error);
      this.failedToGetPodcast = true;
      this.loading = false;
    });
    this.mediaService.chapters.subscribe((chapters) => {
      this.chapters = chapters;
    });
  }
}
