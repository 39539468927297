import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChapterInterface, ChapterResponseInterface, MediaStateInterface } from '../interfaces/public_api';
import { MediaTypeEnum } from '../enums/public_api';
import videoJs from 'video.js';
import 'videojs-contrib-quality-levels';
import * as M3U8FileParser from 'm3u8-file-parser';

@Injectable({
  providedIn: 'root',
})
export class MediaService {

  private readonly mediaState: MediaStateInterface;
  private currentTimeValue: number;
  private oldVolumeValue: number;
  private mediaInstance: videoJs.player;
  private mediaMode = MediaTypeEnum.VIDEO;
  media = new ReplaySubject<MediaStateInterface>(1);
  currentTime = new ReplaySubject<number>(1);
  chapters = new ReplaySubject<ChapterInterface[]>(1);
  modeChange = new BehaviorSubject(this.mediaMode);
  /**
   * Emits every time the media is paused
   */
  paused = new Subject<void>();
  /**
   * Emits every time the media starts to play
   */
  played = new Subject<void>();

  videoSource: string;
  audioSource: string;

  constructor(private httpClient: HttpClient) {
    this.mediaState = {
      isReady: false,
      instance: undefined,
      pause: undefined,
      levels: [],
      currentPlaybackRate: 1,
      playbackRates: [1, 1.5, 2],
      duration: 0,
      muted: false,
      volume: 1,
      isSafari: videoJs.browser.IS_SAFARI,
    };
  }

  setMediaInstance(val: videoJs.player) {
    this.mediaInstance = val;
    this.mediaState.instance = this.mediaInstance;
    this.mediaState.pause = this.mediaInstance.paused();
    this.mediaState.muted = this.mediaInstance.muted();
    this.mediaState.volume = this.mediaInstance.volume();

    this.mediaInstance.one('loadedmetadata', () => {
      this.mediaState.duration = this.mediaInstance.duration();
    });

    this.mediaInstance.on('timeupdate', () => {
      this.currentTimeValue = this.mediaInstance.currentTime();
      this.currentTime.next(this.currentTimeValue);
    });

    return new Observable((observer) => {
      if (!this.mediaState.isReady) {
        this.setDefaultLevel();

        this.setQualityLevelsList();

        this.videoSource = this.mediaInstance.currentSrc();

        this.httpClient.get(this.videoSource, {responseType: 'text'}).subscribe((response) => {
          const reader = new M3U8FileParser();
          reader.read(response);
          this.setChapters(this.getChapters(reader.getResult()));
          this.setAudioSource(this.getAudioSource(reader.getResult()));
          this.mediaState.isReady = true;
          this.media.next(this.mediaState);
          observer.next();
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  switchMedia() {
    const currentTime = this.mediaInstance.currentTime();
    const mediaPaused = this.mediaState.pause;
    this.mediaState.levels = [];

    if (this.mediaMode === MediaTypeEnum.VIDEO) {
      this.mediaMode = MediaTypeEnum.AUDIO;
      this.mediaInstance.src([
        {type: 'application/x-mpegURL', src: this.audioSource},
      ]);
    } else {
      this.mediaMode = MediaTypeEnum.VIDEO;
      this.setDefaultLevel();
      this.mediaInstance.src([
        {type: 'application/x-mpegURL', src: this.videoSource},
      ]);
    }
    this.seekTime(currentTime);
    if (mediaPaused) {
      this.pauseMedia();
    } else {
      this.playMedia();
    }
    this.modeChange.next(this.mediaMode);
  }

  playMedia(): void {
    if (!this.mediaState.isReady) {
      return;
    }
    this.mediaInstance.play().then(() => {
        this.mediaState.pause = this.mediaInstance.paused();
        this.media.next(this.mediaState);
      }
    );
  }

  pauseMedia(): void {
    if (!this.mediaState.isReady) {
      return;
    }
    this.mediaInstance.pause();
    this.mediaState.pause = this.mediaInstance.paused();
    this.media.next(this.mediaState);
  }

  togglePlayMedia(): void {
    if (!this.mediaState.isReady) {
      return;
    }
    if (this.mediaState.pause) {
      this.playMedia();
    } else {
      this.pauseMedia();
    }
  }

  skipTime(skippedTime): void {
    if (!this.mediaState.isReady) {
      return;
    }
    const newTime = this.currentTimeValue + skippedTime;
    this.seekTime(newTime);
  }

  changePlaybackRate(playbackRate: number): void {
    if (!this.mediaState.isReady) {
      return;
    }
    this.mediaState.currentPlaybackRate = playbackRate;
    this.mediaInstance.playbackRate(playbackRate);
  }

  setQualityLevel(newLevelHeight: string | number): void {
    if (!this.mediaState.isReady) {
      return;
    }

    if (this.mediaMode === MediaTypeEnum.AUDIO) {
      this.switchMedia();
      return;
    }

    for (const mediaStateLevel of this.mediaState.levels) {
      mediaStateLevel.enabled = mediaStateLevel.height === newLevelHeight;
    }

    for (const mediaLevel of this.mediaInstance.qualityLevels().levels_) {
      if (newLevelHeight === 'Auto' && mediaLevel.height) {
        mediaLevel.enabled = true;
      } else {
        mediaLevel.enabled = mediaLevel.height === newLevelHeight;
      }
    }
  }

  toggleMute(): void {
    if (!this.mediaState.isReady) {
      return;
    }
    this.mediaState.muted = !this.mediaState.muted;
    if (this.mediaState.muted) {
      this.oldVolumeValue = this.mediaState.volume;
      this.setVolume(0);
    } else {
      this.setVolume(this.oldVolumeValue);
    }
    this.mediaInstance.muted(this.mediaState.muted);
  }

  setVolume(volumeDecimalPercent: number): void {
    if (!this.mediaState.isReady) {
      return;
    }
    this.mediaState.volume = volumeDecimalPercent;
    this.mediaInstance.volume(this.mediaState.volume);
  }

  toggleFullscreen(): void {
    if (!this.mediaState.isReady) {
      return;
    }
    if (!this.mediaInstance.isFullscreen()) {
      this.mediaInstance.requestFullscreen();
    } else {
      this.mediaInstance.exitFullscreen();
    }
  }

  seekTime(time): void {
    if (time < 0) {
      time = 0;
    } else if (time > this.mediaInstance.duration) {
      time = this.mediaInstance.duration;
    }
    this.mediaInstance.currentTime(time);
  }

  private getChapters(playlist) {
    const m3u8Path = this.videoSource.substring(0, this.videoSource.lastIndexOf('/'));
    if (playlist.sessionData) {
      const chaptersJsonUri = `${m3u8Path}/${playlist.sessionData['com.apple.hls.chapters'].undefined.uri}`;
      if (chaptersJsonUri) {
        this.httpClient.get<ChapterResponseInterface[]>(chaptersJsonUri, {responseType: 'json'})
          .pipe(
            map(responseChapters => {
              return responseChapters.map((chapter) => {
                return {
                  title: chapter.titles[0].title,
                  image: `${m3u8Path}/${chapter.images[0].url}`,
                  startTime: chapter['start-time'],
                };
              });
            })
          )
          .subscribe((chapters: ChapterInterface[]) => {
            this.setChapters(chapters);
          });
      }
    }
  }

  private setChapters(chapters) {
    this.chapters.next(chapters);
  }

  private getAudioSource(playlist) {
    const m3u8Path = this.videoSource.substring(0, this.videoSource.lastIndexOf('/'));
    const audioSource = playlist.segments.find((item) => {
      return !item.streamInf.resolution;
    });
    return audioSource ? `${m3u8Path}/${audioSource.url}` : undefined;
  }

  private setAudioSource(audioSource: string) {
    this.audioSource = audioSource;
  }

  private setQualityLevelsList(): void {
    this.mediaInstance.qualityLevels().on('addqualitylevel', (event) => {
      const qualityLevelHeight = event.qualityLevel.height;
      if (!qualityLevelHeight) {
        return;
      }
      let sup: null | string = null;
      if (qualityLevelHeight >= 720 && qualityLevelHeight < 1080) {
        sup = 'HD';
      } else if (qualityLevelHeight >= 1080) {
        sup = 'Full HD';
      }
      this.mediaState.levels.push({
        height: qualityLevelHeight,
        enabled: false,
        sup,
      });
    });
  }

  private setDefaultLevel() {
    this.mediaState.levels.push({
      height: 'Auto',
      enabled: true,
      sup: null,
    });
  }
}
