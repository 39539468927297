<div class="alert alert-warning text-center" *ngIf="failedToGetPodcast">
  Sorry, an error occurred and we were unable to get this podcast.
</div>

<main class="media-page" *ngIf="!loading && !failedToGetPodcast">
  <section *ngIf="!configService.embedded" class="media-page__media">

    <header class="media-page__header" *ngIf="podcast?.state !== podcastState.LIVE; else liveTitle">
      <div class="media-page__header-top">
        <h2 class="media-page__title">{{ podcast?.title || 'Sans titre' }}</h2>
        <div class="media-page__buttons">
          <app-sharing-button *ngIf="configService.professor"
                              class="media-page__sharing-button"
                              data-qa='sharing-btn'>
          </app-sharing-button>
          <app-dl-button [podcastId]="podcast.id" ui-id="download-player-page"></app-dl-button>
        </div>
      </div>
      <ul class="media-data-list">
        <li class="media-data text">{{podcast.views}} <span i18n="@@views">vues</span></li>
        <li *ngIf="configService.professor" class="media-data text">{{podcast.externalViews}} <span i18n="@@externalViews">vues externes</span></li>
        <li class="media-data">{{podcast.visitors}} <span i18n="@@visitors">visiteurs uniques</span></li>
      </ul>
    </header>

    <div class="media-page__container">
      <div class="media-height-limiter" [class.media-height-limiter--live]="podcast?.state === podcastState.LIVE">
        <div class="media-fluid-wrapper">
          <div class="media-container media-height-limiter"
               [class.media-height-limiter--live]="podcast?.state === podcastState.LIVE">
            <ng-container *ngIf="podcast?.state !== podcastState.LIVE; else livePlayer">
              <app-media-player></app-media-player>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <tabset class="media-page__tabs" *ngIf="podcast?.state !== podcastState.LIVE; else liveDescription">
      <tab>
        <ng-template tabHeading>
          <span i18n="@@about">A propos</span>
        </ng-template>
        <div class="media-page__infos">
          <div class="media-page__col-left">
            <time class="media-page__infos-title">
              <span i18n="@@publishAt">Publié le</span> {{ (podcast?.uliegeDateCreation | date:'dd/MM/yy') || 'N/A' }}
            </time>
            <div *ngIf="podcast?.description; else noDescription" [innerHTML]="podcast?.description"></div>
          </div>
          <div class="media-page__col-right contact">
            <h2 class="contact__title">
              Contact
            </h2>
            <ul class="contact__list">
              <li *ngIf="podcast.contact || podcast.contactUrl; else noContact">
                <a class="contact__person"
                   [class.disabled]="podcast.contactUrl === null"
                   [href]="podcast.contactUrl" target="_blank">
                  {{podcast.contact || 'Contact'}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </tab>
      <tab>
        <ng-template tabHeading>
          <span i18n="@@help">Aide</span>
        </ng-template>
        <div class="media-page__infos">
          <div class="media-page__col-left only-lg">
            <p class="media-page__infos-title" i18n="@@shortcuts">
              Raccourcis clavier
            </p>
            <div class="shortcut-list-wrapper">
              <ul class="shortcut-list shortcut-list--left shortcut list-unstyled">
                <li class="shortcut__label">
                  <ng-container i18n="@@shortcutPlayPause">Play /Pause</ng-container>
                  <span class="shortcut__key-wrapper shortcut__key-wrapper--extended">
                  <span i18n="@@shortcutSpace">Espace</span>
                    </span>
                </li>
                <li class="shortcut__label">
                  <ng-container i18n="@@shortcutIncreaseSpeed">Augmenter la vitesse</ng-container>
                  <span class="shortcut__key-wrapper">
                  <span>&gt;</span>
                  </span>
                </li>
                <li class="shortcut__label">
                  <ng-container i18n="@@shortcutDecreaseSpeed">Diminuer la vitesse</ng-container>
                  <span class="shortcut__key-wrapper">
                  <span>&lt;</span>
                  </span>
                </li>
                <li class="shortcut__label">
                  <ng-container i18n="@@shortcutFullScreen">Plein écran</ng-container>
                  <span class="shortcut__key-wrapper">
                  <span>f</span>
                  </span>
                </li>
              </ul>
              <ul class="shortcut-list shortcut list-unstyled">
                <li class="shortcut__label">
                  <ng-container i18n="@@shortcutAdvanceBy">Avancer de 15s</ng-container>
                  <span class="shortcut__key-wrapper">
                  <span>&rarr;</span>
                  </span>
                </li>
                <li class="shortcut__label">
                  <ng-container i18n="@@shortcutGoBack">Reculer de 15s</ng-container>
                  <span class="shortcut__key-wrapper">
                  <span>&larr;</span>
                  </span>
                </li>
                <li class="shortcut__label">
                  <ng-container i18n="@@shortcutAudioOnly">Version audio</ng-container>
                  <span class="shortcut__key-wrapper">
                  <span>v</span>
                  </span>
                </li>
                <li class="shortcut__label">
                  <ng-container i18n="@@shortcutMute">Mute</ng-container>
                  <span class="shortcut__key-wrapper">
                  <span>m</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="media-page__col-right contact">
            <h2 class="contact__title" i18n="@@technicalSupport">
              Assistance technique
            </h2>
            <ul class="contact__list">
              <li>
                <a class="contact__person contact__person--info" href="https://my.segi.uliege.be/" target="_blank">
                  https://my.segi.uliege.be/
                </a>
              </li>
            </ul>
          </div>
        </div>
      </tab>
      <tab heading="Chapitres" [customClass]="'only-sm'">
        <div class="media-page__chapters">
          <app-chapter [chapter]="chapter" *ngFor="let chapter of chapters"></app-chapter>
        </div>
      </tab>
    </tabset>
  </section>
  <app-embed-player *ngIf="configService.embedded"></app-embed-player>
</main>

<div class="spinner-container" *ngIf="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only" i18n="@@loading">Chargement...</span>
  </div>
</div>

<ng-template #liveTitle>
  <div class="media-page__header media-page__header--live">
    <div class="chip-live chip-live--big">
      <div class="live-indicator"></div>
      <span i18n="@@streamingLive">Diffusion en direct</span>
    </div>
    <time class="media-page__title media-page__publish-date">
      {{ (podcast.updatedAt | date:'dd/MM/yy') }}&nbsp;<span
      i18n="@@a">à</span>&nbsp;{{ (podcast.updatedAt | date:'HH:mm') }}
    </time>
  </div>
</ng-template>

<ng-template #livePlayer>
  <app-live-player></app-live-player>
</ng-template>

<ng-template #liveDescription>
  <section class="media-page__infos media-page__infos--live tab-content" *ngIf="podcast">
    <h2 class="media-page__col-left media-page__col-left--live">
      {{ podcast.title || 'Sans titre' }}
    </h2>
    <div class="media-page__col-right contact">
      <h2 class="contact__title">
        Contact
      </h2>
      <ul class="contact__list">
        <li *ngIf="podcast.contact || podcast.contactUrl; else noContact">
          <a class="contact__person"
             [class.disabled]="podcast.contactUrl === null"
             [href]="podcast.contactUrl" target="_blank">
            {{podcast.contact || 'Contact'}}
          </a>
        </li>
      </ul>
    </div>
  </section>
</ng-template>

<ng-template #noContact>
  <li class="contact__no-contact" i18n="@@noContact">
    Pas de contact.
  </li>
</ng-template>

<ng-template #noDescription>
  <p>
    Pas de description.
  </p>
</ng-template>
