import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from '../core/core.module';
import { BookmarksModule } from '../bookmarks/bookmarks.module';
import { MediaPlayerComponent } from './media-player/media-player.component';
import { ControlBarComponent } from './control-bar/control-bar.component';
import { ProgressControlComponent } from './progress-control/progress-control.component';
import { MediaTimerComponent } from './media-timer/media-timer.component';
import { VolumeControlComponent } from './volume-control/volume-control.component';
import { FullscreenControlComponent } from './fullscreen-control/fullscreen-control.component';
import { SkipControlComponent } from './skip-control/skip-control.component';
import { PlaybackrateControlComponent } from './playbackrate-control/playbackrate-control.component';
import { MediaSettingsComponent } from './media-settings/media-settings.component';
import { PlayControlComponent } from './play-control/play-control.component';
import { MediaPageComponent } from './media-page/media-page.component';
import { MediaService } from './services/media.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChapterComponent } from './chapter/chapter.component';
import { ChaptersControlComponent } from './chapters-control/chapters-control.component';
import { LivePlayerComponent } from './live-player/live-player.component';
import { LiveVideoComponent } from './live-video/live-video.component';
import { DlButtonComponent } from './dl-button/dl-button.component';
import { SharingButtonComponent } from './sharing-button/sharing-button.component';
import { EmbedPlayerComponent } from './embed-player/embed-player.component';

@NgModule({
  declarations: [
    MediaPlayerComponent,
    ControlBarComponent,
    ProgressControlComponent,
    MediaTimerComponent,
    VolumeControlComponent,
    FullscreenControlComponent,
    SkipControlComponent,
    PlaybackrateControlComponent,
    MediaSettingsComponent,
    PlayControlComponent,
    MediaPageComponent,
    ChapterComponent,
    ChaptersControlComponent,
    LivePlayerComponent,
    LiveVideoComponent,
    DlButtonComponent,
    SharingButtonComponent,
    EmbedPlayerComponent,
  ],
  imports: [
    CoreModule,
    BookmarksModule,
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        component: MediaPageComponent,
      }
    ]),
    TabsModule.forRoot(),
    HttpClientModule
  ],
  providers: [MediaService],
  entryComponents: [LiveVideoComponent],
})
export class MediaModule {
}
