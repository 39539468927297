import { Component, ElementRef } from '@angular/core';
import { ConfigService } from './core/services/config.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading = true;
  error = false;

  constructor(private configService: ConfigService, appEl: ElementRef) {
    const apiUrl = appEl.nativeElement.getAttribute('data-api-url') || environment.apiUrl;
    this.configService.professor = appEl.nativeElement.hasAttribute('data-is-professor');
    this.configService.embedded = appEl.nativeElement.hasAttribute('data-embed');

    if (!this.configService.setApiUrl(apiUrl)) {
      this.error = true;
    }
    this.loading = false;
  }
}
