<app-progress-control></app-progress-control>

<div class="control-bar__controls">
  <app-play-control></app-play-control>
  <app-skip-control [skippedTime]="-15"></app-skip-control>
  <app-skip-control [skippedTime]="15"></app-skip-control>
  <app-playbackrate-control></app-playbackrate-control>
  <app-media-timer></app-media-timer>

  <div class="control-bar__menu-right">
    <app-chapters-control *ngIf="chapters"></app-chapters-control>
    <app-volume-control></app-volume-control>
    <app-media-settings></app-media-settings>
    <app-fullscreen-control></app-fullscreen-control>
  </div>
</div>
