<div class="volume-control-container">

  <button class="btn btn-media-control volume-control"
          [class.volume-control--muted]="media?.muted"
          (click)="toggleMute()">
    <span class="sr-only">Volume</span>
  </button>

  <div class="volume-wrapper"
       (mousedown)="mouseDownOnVolume($event)"
       (mousemove)="mouseMoveOnVolume($event)"
       [class.volume-wrapper--is-grabbing]="interactWithVolume">
    <div #volume class="volume">
      <div class="volume__slider" [ngStyle]="{'height': volumeSliderHeight}"></div>
    </div>
  </div>
</div>
