import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import videoJs from 'video.js';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, delay, retryWhen, take, concat } from 'rxjs/operators';

@Component({
  selector: 'app-live-video',
  templateUrl: './live-video.component.html',
  styleUrls: ['./live-video.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LiveVideoComponent implements AfterViewInit, OnDestroy {
  @Input() videoId: string;
  @Input() shrunk: boolean;
  @Input() feedUrl: string;
  @Output() errorOccurred = new EventEmitter<any>();
  @Output() videoClicked = new EventEmitter<string>();

  private videoJsInstance;
  isLoading = true;

  constructor(private httpClient: HttpClient, private changeDetector: ChangeDetectorRef) {
  }

  videoClick() {
    this.videoClicked.emit(this.videoId);
  }

  ngAfterViewInit() {
    this.tryToPreloadPlaylist().subscribe(() => {
      this.initPlayer();
      this.isLoading = false;
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy() {
    this.videoJsInstance.dispose();
  }

  private tryToPreloadPlaylist(): Observable<any> {
    return this.httpClient.get(this.feedUrl, {responseType: 'text'}).pipe(
      retryWhen(errors => errors.pipe(delay(100), take(100), concat(throwError('ERROR')))),
      catchError(() => of(''))
    );
  }

  private initPlayer() {
    this.videoJsInstance = videoJs(this.videoId, {
      autoplay: false,
      controls: false,
      sources: [{
        src: this.feedUrl,
        type: 'application/x-mpegURL',
        withCredentials: false,
      }]
    }, (err) => {
      this.errorOccurred.emit({videoId: this.videoId, error: err});
    });
  }
}
