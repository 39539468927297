import { Component, HostListener } from '@angular/core';
import { MediaService } from '../services/media.service';
import { MediaStateInterface } from '../interfaces/media-state.interface';
import { MediaTypeEnum } from '../enums/media-type.enum';

@Component({
  selector: 'app-media-settings',
  templateUrl: './media-settings.component.html',
  styleUrls: ['./media-settings.component.scss']
})
export class MediaSettingsComponent {
  media: MediaStateInterface;
  inactivityTimeout: number;
  mediaType = MediaTypeEnum;
  mode: MediaTypeEnum;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'v') {
      this.mediaService.switchMedia();
    }
  }

  constructor(public mediaService: MediaService) {
    this.mediaService.media.subscribe((media) => {
      this.media = media;
    });
    this.mediaService.modeChange.subscribe((mode) => {
      this.mode = mode;
    });
  }

  onShowModal(): void {
    this.inactivityTimeout = this.media.instance.options().inactivityTimeout;
    this.media.instance.options().inactivityTimeout = 0;
  }

  onHideModal(): void {
    this.media.instance.options().inactivityTimeout = this.inactivityTimeout;
  }

  setQualityLevel(level) {
    this.mediaService.setQualityLevel(level);
  }

  changePlaybackRate(newPlaybackRate: number) {
    this.mediaService.changePlaybackRate(newPlaybackRate);
  }
}
