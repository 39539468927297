export class SharingBtn {
  container: HTMLElement;
  button: HTMLElement;

  private htmlTemplate: string;

  constructor(container: string | HTMLElement, template?: string | HTMLElement) {
    this.setContainer(container);
    this.setTemplate(template);
  }

  createButton() {
    const domParser = new DOMParser();
    const document = domParser.parseFromString(this.htmlTemplate, 'text/html');
    const button = document.querySelector('body > *');
    this.container.appendChild(button);
  }

  private setTemplate(template: string | HTMLElement) {
    const defaultTemplate = `
      <button class="btn btn-highlight btn--big" role="button" data-toggle="modal" data-target=".sharing-modal">
        Partager ce podcast
      </button>`;
    let tmpl: string;
    if (!template) {
      tmpl = defaultTemplate;
    } else if (typeof template === 'string') {
      const element = document.querySelector(`[data-template-id=${template}]`);
      if (element) {
        tmpl = element.outerHTML;
      } else {
        tmpl = defaultTemplate;
        console.warn(`Sharing button container could not be found via id ${template}. Fallback to default template.`);
      }
    } else if ((template as HTMLElement).outerHTML) {
      tmpl = (template as HTMLElement).outerHTML;
    } else {
      throw new Error('Invalid template provided');
    }

    this.htmlTemplate = tmpl;
  }

  private setContainer(container: string | HTMLElement) {
    const error = 'Container should be an element or an ID of an element';
    if (typeof container === 'string') {
      if (this.isIdSelector(container)) {
        this.container = document.querySelector(container);
      } else {
        // tslint:disable-next-line:no-console
        console.error(error);
      }
    } else if (container) {
      this.container = container as HTMLElement;
    } else {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  }

  private isIdSelector(selector: string) {
    return /^#/.test(selector);
  }
}
