import { Component, HostListener } from '@angular/core';
import { MediaService } from '../services/media.service';
import { MediaStateInterface } from '../interfaces/media-state.interface';

@Component({
  selector: 'app-play-control',
  templateUrl: './play-control.component.html',
  styleUrls: ['./play-control.component.scss']
})
export class PlayControlComponent {
  media: MediaStateInterface;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
      this.togglePlay();
    }
  }

  constructor(private mediaService: MediaService) {
    this.mediaService.media.subscribe((media) => {
      this.media = media;
    });
  }

  togglePlay() {
    this.mediaService.togglePlayMedia();
  }
}
