import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { PodcastInterface, PodcastResponseInterface } from '../interfaces/public_api';
import { ConfigService } from './config.service';
import videoJs from 'video.js';

@Injectable()
export class PodcastService {
  /**
   * Cached data of the podcast recovered from the service
   * @private
   */
  readonly podcast: Observable<PodcastInterface>;

  /**
   * Error handler
   * @param error
   * @private
   */
  private static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `message was: ${error.error.errors[0].message}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(error);
  }

  constructor(private httpClient: HttpClient, private configService: ConfigService) {

    this.podcast = this.httpClient.get<PodcastResponseInterface>(this.configService.apiUrl, { responseType: 'json' })
      .pipe(
        map(response => {
          const result = response.result;
          return {
            id: result.id,
            title: result.name,
            state: result.state,
            length: result.length,
            startTime: result.progression,
            description: result.description,
            updatedAt: result.updatedAt,
            media: result.m3u8Url,
            roomId: result.roomId,
            feeds: result.feeds,
            contact: result.contact,
            contactUrl: result.contactUrl,
            uliegeDateCreation: result.uliegeDateCreation,
            views: result.views,
            externalViews: result.externalViews,
            visitors: result.visitors,
          };
        }),
        shareReplay(),
        catchError(PodcastService.handleError) // then handle the error
      );
  }

  /**
   * Get podcast from api or the cache if it has already been fetched
   */
  getPodcast(): Observable<PodcastInterface> {
    return this.podcast;
  }

  setNewPodcastTime(videoInstance: videoJs.player, addView: boolean, addExternalView: boolean): Observable<any> {
    return this.httpClient.patch<any>(this.configService.apiUrl, {
      progression: videoInstance.currentTime(),
      read: null,
      duration: videoInstance.duration(),
      addView,
      addExternalView
    })
      .pipe(
        catchError(PodcastService.handleError),
      );
  }
}
