import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {DownloadBtn} from '../../core/models';

@Component({
  selector: 'app-dl-button',
  templateUrl: './dl-button.component.html',
  styleUrls: ['./dl-button.component.scss']
})
export class DlButtonComponent implements OnInit {

  element: HTMLElement;

  @Input() podcastId: string;

  constructor(
    private elementRef: ElementRef
  ) {
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    const dlBtn = new DownloadBtn(this.element, this.podcastId, 'dl-btn-tmpl');
    dlBtn.createButton();
  }
}
