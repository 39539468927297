import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MediaService } from '../services/media.service';
import { MediaStateInterface } from '../interfaces/media-state.interface';

@Component({
  selector: 'app-volume-control',
  templateUrl: './volume-control.component.html',
  styleUrls: ['./volume-control.component.scss']
})
export class VolumeControlComponent {
  @ViewChild('volume', {read: ElementRef, static: false}) volume: ElementRef;

  media: MediaStateInterface;
  volumeSliderHeight: string;
  interactWithVolume = false;

  @HostListener('document:mouseup') mouseUp(): void {
    this.interactWithVolume = false;
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'm') {
      this.mediaService.toggleMute();
    }
  }

  constructor(private mediaService: MediaService) {
    this.mediaService.media.subscribe((media) => {
      this.media = media;
      this.updateVolumeSliderHeight(this.media.volume);
    });
  }

  updateVolumeSliderHeight(volumeDecimalPercent: number): void {
    this.volumeSliderHeight = (volumeDecimalPercent * 100).toString() + '%';
  }

  mouseDownOnVolume(e: MouseEvent): void {
    this.interactWithVolume = true;
    this.changeVolume(e);
  }

  mouseMoveOnVolume(e: MouseEvent): void {
    if (!this.interactWithVolume) {
      return;
    }
    this.changeVolume(e);
  }

  changeVolume(e: MouseEvent): void {
    if (this.media.muted) {
      this.mediaService.toggleMute();
    }
    const volumeBarTop = this.volume.nativeElement.getBoundingClientRect().top;
    const volumeBarHeight = this.volume.nativeElement.getBoundingClientRect().height;
    const newVolumePercent = Math.round(volumeBarHeight - (e.clientY - volumeBarTop)) / volumeBarHeight;
    if (newVolumePercent > 1 || newVolumePercent < 0) {
      return;
    }
    this.mediaService.setVolume(newVolumePercent);
    this.updateVolumeSliderHeight(newVolumePercent);
  }

  toggleMute(): void {
    this.mediaService.toggleMute();
    this.updateVolumeSliderHeight(this.media.volume);
  }
}
