<div class="btn-group only-lg" dropdown [dropup]="true"
     (onShown)="onShowChapters()"
     (onHidden)="onHideChapters()">
  <button dropdownToggle class="dropdown-toggle btn btn-media-control chapters-control" type="button"></button>
  <div *dropdownMenu class="dropdown-menu media-dropdown-menu-container">
    <div class="chapters media-dropdown-menu ">
      <h3 class="chapters__title" i18n="@@chapters">Chapitres</h3>
      <ul class="chapters__list" role="menu">
        <li role="menuitem" class="dropdown-item" *ngFor="let chapter of chapters">
          <app-chapter [chapter]="chapter"></app-chapter>
        </li>
      </ul>
    </div>
  </div>
</div>
